import React from "react"
import cx from "classnames"

import * as styles from "./styles.module.css"

interface BadgeProps {
  text: string
  icon?: string
  index: number
  textWhite?: boolean
  textColor?: string
  bgColor?: string
}
const Badges = ({
  text,
  icon,
  index,
  textWhite,
  bgColor,
  textColor,
}: BadgeProps) => {
  const bagdesClass = cx(
    "bagdes py-0.5 px-1 mr-2 font-bold uppercase sm:text-sm md:text-sm lg:text-base border border-solid flex items-center",
    styles.bagdes,
    { "bg-cyan border-cyanLight": !bgColor && index === 0 },
    { "text-white": (textWhite || index !== 0) && !textColor },
    textColor,
    bgColor
  )

  return (
    <div>
      <span className={bagdesClass}>
        {index === 0 && icon && (
          <span
            className="mr-1 text-xs"
            dangerouslySetInnerHTML={{ __html: icon }}
          />
        )}
        {text}
      </span>
    </div>
  )
}
export default Badges
